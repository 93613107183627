import { useRelayMutation } from '@/lib';
import {
  CreateShopDocument,
  CreateShopMutation,
  CreateShopMutationVariables,
  useViewerShopsQuery,
  useCheckShopSlugQuery,
  useShopBySlugQuery,
  useShopsQuery,
  useSearchShopsQuery,
  ShopsQuery,
  ShopsQueryVariables,
  ShopFragment,
  SearchShopsQuery,
  SearchShopsQueryVariables,
  SetupShopMutation,
  SetupShopMutationVariables,
  SetupShopDocument,
  useAnyShopBySlugQuery,
  CreateLinkTokenMutation,
  CreateLinkTokenMutationVariables,
  CreateLinkTokenDocument,
  ExchangePublicTokenMutation,
  ExchangePublicTokenMutationVariables,
  ExchangePublicTokenDocument,
  useSellerCategoriesQuery,
  useSellerSubCategoriesQuery,
  UpdateShopCategoriesMutation,
  UpdateShopCategoriesMutationVariables,
  UpdateShopCategoriesDocument,
  UpdateShopMutation,
  UpdateShopMutationVariables,
  UpdateShopDocument,
  CreateShopCustomizationMutation,
  CreateShopCustomizationMutationVariables,
  CreateShopCustomizationDocument,
  AddShopImagesMutation,
  AddShopImagesDocument,
  AddShopImagesMutationVariables,
  UpdateShopSubcategoriesMutation,
  UpdateShopSubcategoriesMutationVariables,
  UpdateShopSubcategoriesDocument
} from '@/api';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';
import { QueryHookOptions } from '@apollo/client';

export function useCreateShop() {
  return useRelayMutation<CreateShopMutation, CreateShopMutationVariables, 'createShop'>(
    CreateShopDocument,
    'createShop'
  );
}

export function useShopBySlug(slug: string) {
  const { data, ...rest } = useShopBySlugQuery({
    variables: {
      slug
    }
  });
  return { shop: data?.shopBySlug, ...rest };
}

export function useSellerCategories() {
  const { data, ...rest } = useSellerCategoriesQuery();
  return { sellercategories: data?.sellerCategories?.nodes || [], ...rest };
}

export function useSellerSubCategories(categoryIds: string[]) {
  const { data, ...rest } = useSellerSubCategoriesQuery({
    variables: {
      input: categoryIds
    }
  });
  return { sellersubcategories: data?.sellerSubCategories, ...rest };
}

export function useAnyShopBySlug(slug: string) {
  const { data, ...rest } = useAnyShopBySlugQuery({
    variables: {
      slug
    }
  });
  return { shop: data?.anyShopBySlug, ...rest };
}

export function useShopSlugExists(slug: string) {
  const { data, refetch, ...rest } = useCheckShopSlugQuery({
    variables: {
      slug
    },
    fetchPolicy: 'no-cache',
    skip: !Boolean(slug)
  });

  const checkExists = async (slug: string) => {
    const result = await refetch({
      slug
    });
    return Boolean(result?.data?.shopBySlug?.id);
  };

  return { slugExists: Boolean(data?.shopBySlug?.id), checkExists, ...rest };
}

export function useViewerShops() {
  const { data, ...rest } = useViewerShopsQuery();

  return { shops: data?.viewer?.shops?.nodes ?? [], viewer: data?.viewer ?? null, ...rest };
}

export function useShops(options?: QueryHookOptions<ShopsQuery, ShopsQueryVariables>) {
  const { data, fetchMore: fetchMoreFn, ...rest } = useShopsQuery(options);
  const [fetchingMoreShops, setFetchingMoreShops] = useState<boolean>(false);

  const fetchMoreShops = useCallback(async () => {
    setFetchingMoreShops(true);
    try {
      await fetchMoreFn({
        variables: {
          cursor: data?.shops?.pageInfo.endCursor
        }
      });
    } finally {
      setFetchingMoreShops(false);
    }
  }, [data, fetchMoreFn, setFetchingMoreShops]);

  return {
    shops: (data?.shops?.edges?.map((edge) => edge.node) ?? []) as ShopFragment[],
    hasMoreShops: data?.shops?.pageInfo.hasNextPage ?? false,
    fetchingMoreShops,
    fetchMoreShops,
    ...rest
  };
}

export function useSearchShops(query: string, options?: QueryHookOptions<SearchShopsQuery, SearchShopsQueryVariables>) {
  const {
    data,
    fetchMore: fetchMoreFn,
    ...rest
  } = useSearchShopsQuery({
    fetchPolicy: 'network-only',
    variables: {
      query
    },
    ...options
  });
  const [fetchingMoreShopResults, setFetchingMoreShopResults] = useState<boolean>(false);

  const fetchMoreShopResults = useCallback(async () => {
    setFetchingMoreShopResults(true);
    try {
      await fetchMoreFn({
        variables: {
          cursor: data?.searchShops?.pageInfo.endCursor
        }
      });
    } finally {
      setFetchingMoreShopResults(false);
    }
  }, [data, fetchMoreFn, setFetchingMoreShopResults]);

  return {
    shopResults: (data?.searchShops?.edges?.map((edge) => edge.node) ?? []) as ShopFragment[],
    hasMoreShopResults: data?.searchShops?.pageInfo.hasNextPage ?? false,
    fetchingMoreShopResults,
    fetchMoreShopResults,
    ...rest
  };
}

type UseFilterShopOptions = {
  skip?: boolean;
  queryParam?: string;
  debounceDuration?: number;
  disableQueryParam?: boolean;
};

export function useSetUpShop() {
  return useRelayMutation<SetupShopMutation, SetupShopMutationVariables, 'setupShop'>(SetupShopDocument, 'setupShop');
}

export function useUpdateShopCategories() {
  return useRelayMutation<UpdateShopCategoriesMutation, UpdateShopCategoriesMutationVariables, 'updateShopCategories'>(UpdateShopCategoriesDocument, 'updateShopCategories');
}
export function useUpdateShopSubCategories() {
  return useRelayMutation<UpdateShopSubcategoriesMutation, UpdateShopSubcategoriesMutationVariables, 'updateShopSubcategories'>(UpdateShopSubcategoriesDocument, 'updateShopSubcategories');
}
export function useUpdateShop() {
  return useRelayMutation<UpdateShopMutation, UpdateShopMutationVariables, 'updateShop'>(UpdateShopDocument, 'updateShop');
}

export function useCreateLinkToken() {
  return useRelayMutation<CreateLinkTokenMutation, CreateLinkTokenMutationVariables, 'createLinkToken'>(CreateLinkTokenDocument, 'createLinkToken');
}

export function useExchangePublicToken() {
  return useRelayMutation<ExchangePublicTokenMutation, ExchangePublicTokenMutationVariables, 'exchangePublicToken'>(ExchangePublicTokenDocument, 'exchangePublicToken');
}

export function useFilterShops(options?: UseFilterShopOptions) {
  const { query, replace, pathname } = useRouter();

  const queryParam = options?.queryParam ?? 'q';
  const disableQueryParam = options?.disableQueryParam ?? false;
  const debounceDuration = options?.debounceDuration ?? 300;

  const [localQuery, setLocalQuery] = useState<string>('');
  const currentQuery = disableQueryParam ? localQuery : (query[queryParam] as string) ?? '';
  const [searchQuery, setSearchQuery] = useState<string>(currentQuery);

  const {
    loading: loadingShops,
    shops: baseShops,
    hasMoreShops: haseMoreBaseShops,
    fetchingMoreShops: fetchingMoreBaseShops,
    fetchMoreShops: fetchMoreBaseShops
  } = useShops({
    skip: options?.skip || Boolean(currentQuery)
  });

  const {
    loading: loadingShopResults,
    shopResults,
    hasMoreShopResults,
    fetchingMoreShopResults,
    fetchMoreShopResults
  } = useSearchShops(currentQuery, {
    skip: options?.skip || !Boolean(currentQuery)
  });

  const updateQueryParam = useCallback(
    async (searchQuery: string) => {
      if (searchQuery) {
        await replace({
          pathname,
          query: { shopSlug: query.shopSlug, [queryParam]: searchQuery }
        });
      } else {
        await replace({
          pathname,
          query: { shopSlug: query.shopSlug }
        });
      }
    },
    [pathname, query, replace]
  );

  const search = useCallback(
    async (searchQuery: string) => {
      if (disableQueryParam) {
        setLocalQuery(searchQuery);
      } else {
        await updateQueryParam(searchQuery);
      }
    },
    [disableQueryParam, updateQueryParam]
  );

  useDebounce(
    () => {
      if (searchQuery !== currentQuery) search(searchQuery);
    },
    debounceDuration,
    [searchQuery]
  );

  let shops = baseShops;
  let hasMoreShops = haseMoreBaseShops;
  let fetchingMoreShops = fetchingMoreBaseShops;
  let fetchMoreShops = fetchMoreBaseShops;

  if (Boolean(currentQuery)) {
    shops = shopResults;
    hasMoreShops = hasMoreShopResults;
    fetchingMoreShops = fetchingMoreShopResults;
    fetchMoreShops = fetchMoreShopResults;
  }

  return {
    loading: loadingShops,
    searching: loadingShopResults,
    shops,
    hasMoreShops,
    fetchingMoreShops,
    fetchMoreShops,
    searchQuery,
    setSearchQuery,
    search
  };
}
export function useCreateShopCustomization() {
  return useRelayMutation<
    CreateShopCustomizationMutation,
    CreateShopCustomizationMutationVariables,
    'createShopCustomization'
  >(CreateShopCustomizationDocument, 'createShopCustomization');
}

export function useAddShopImages() {
  return useRelayMutation<AddShopImagesMutation, AddShopImagesMutationVariables, 'addShopImages'>(
    AddShopImagesDocument,
    'addShopImages'
  );
}
