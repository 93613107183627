import {
  AddressInput,
  CreateCustomPackageDocument,
  CreateCustomPackageMutation,
  CreateCustomPackageMutationVariables,
  CreatePackageDocument,
  CreatePackageMutation,
  CreatePackageMutationVariables,
  DeletePackageDocument,
  DeletePackageMutation,
  DeletePackageMutationVariables,
  PackItemsDocument,
  PackItemsMutation,
  PackItemsMutationVariables,
  PurchaseShippingLabelDocument,
  PurchaseShippingLabelMutation,
  PurchaseShippingLabelMutationVariables,
  ShipmentRatesDocument,
  ShipmentRatesMutation,
  ShipmentRatesMutationVariables,
  ShippingProfileFragment,
  UpdatePackageDocument,
  UpdatePackageMutation,
  UpdatePackageMutationVariables,
  useShippingPackagesForShopQuery,
  useShopShippingProfilesQuery,
  ValidateAddressDocument,
  ValidateAddressQuery,
  ValidateAddressQueryVariables
} from '@/api';
import { useApolloClient } from '@apollo/client';
import { useRelayMutation } from '../../lib';

export function useShopShippingProfiles(shopId: string, skip?: boolean) {
  const { data, ...rest } = useShopShippingProfilesQuery({
    variables: {
      shopId
    },
    skip
  });

  return {
    shippingProfiles:
      data?.shippingProfilesByShop?.edges?.map((edge) => edge.node) ?? ([] as ShippingProfileFragment[]),
    ...rest
  };
}

export function useShippingPackagesForShop(shopId: string, skip?: boolean) {
  const { data } = useShippingPackagesForShopQuery({
    variables: {
      shopId
    },
    skip
  });

  return {
    shippingPackagesForShop:data?.shippingPackagesForShop
  };
}

export function useCreateCustomPackage() {
  return useRelayMutation<CreateCustomPackageMutation, CreateCustomPackageMutationVariables, 'createCustomPackage'>(
    CreateCustomPackageDocument,
    'createCustomPackage'
  );
}

export function useCreatePackage() {
  return useRelayMutation<CreatePackageMutation, CreatePackageMutationVariables, 'createPackage'>(
    CreatePackageDocument,
    'createPackage'
  );
}

export function useDeletePackage() {
  return useRelayMutation<DeletePackageMutation, DeletePackageMutationVariables, 'deletePackage'>(
    DeletePackageDocument,
    'deletePackage'
  );
}

export function useUpdatePackage() {
  return useRelayMutation<UpdatePackageMutation, UpdatePackageMutationVariables, 'updatePackage'>(
    UpdatePackageDocument,
    'updatePackage'
  );
}

export function usePackItems() {
  return useRelayMutation<PackItemsMutation, PackItemsMutationVariables, 'packItems'>(
    PackItemsDocument,
    'packItems'
  );
}

export function useShipmentRates() {
  return useRelayMutation<ShipmentRatesMutation, ShipmentRatesMutationVariables, 'shipmentRates'>(
    ShipmentRatesDocument,
    'shipmentRates'
  );
}

export function usePurchaseShippingLabel() {
  return useRelayMutation<PurchaseShippingLabelMutation, PurchaseShippingLabelMutationVariables, 'purchaseShippingLabel'>(
    PurchaseShippingLabelDocument,
    'purchaseShippingLabel'
  );
}

export function useValidateAddress() {
  const client = useApolloClient();

  return async (address: AddressInput) => {
    const { data } = await client.query<ValidateAddressQuery, ValidateAddressQueryVariables>({
      query: ValidateAddressDocument,
      variables: {
        address
      }
    });

    return data?.validateAddress ?? null;
  };
}
